import { LogLevel } from '@grafana/faro-web-sdk';
import { config } from '../config';
import { getInvalidDomains } from '../services/domains.service';

const { requestIdleCallback = setTimeout } = window;
const { domainValidationWarningTimeout: timeout, domainValidationWarningExpiry = '' } = config;
const expired = domainValidationWarningExpiry && new Date(domainValidationWarningExpiry) < new Date;
const AlertTrigger = {
    error: 'error'
} as const;
const ErrorMessage = {
    errorGettingInvalidDomains: 'Error Getting Invalid Domains'
} as const;
const LogMessage = {
    displayInvalidDomainBanner: 'DISPLAY_INVALID_DOMAIN_BANNER',
    invalidDomainCount: 'INVALID_DOMAIN_COUNT'
} as const;
const EventMessage = {
    invalidDomainBannerCloseClick: 'INVALID_DOMAIN_BANNER_CLOSE_CLICK'
} as const;
const Selector = {
    alert: '#alert',
    closeButton: '#dismiss-message',
    warningTemplate: '#domain-validation-warning-template',
    warningMessage: '#alert #domain-validation-warning-message'
} as const;

export function displayDomainValidationWarning() {
    const { documentElement } = document;
    const { dataset } = documentElement;

    dataset.domainValidationWarningExpiryDate = domainValidationWarningExpiry;
    dataset.domainValidationWarningExpired = expired.toString();

    setTimeout(main, 2000);
}

async function main() {
    const { EV_CORE_META = {} } = window;
    const { u = '-1' } = EV_CORE_META;
    const userId = Number(u);

    if (!expired && userId >= 0) {
        await setInvalidDomains();
    }

    requestIdleCallback(displayWarning);
}

async function setInvalidDomains() {
    const { EV_LOGGER: logger } = window;
    const { documentElement } = document;
    const { dataset } = documentElement;
    const invalidDomainsResponse = await getInvalidDomains().catch(handleGetInvalidDomainsError);
    const invalidDomains = invalidDomainsResponse?.invalidDomains;
    const atrributes = {
        level: LogLevel.INFO,
        context: {
            invalidDomains: '0'
        }
    };

    if (Array.isArray(invalidDomains)) {
        dataset.invalidDomains = invalidDomains.length.toString();

        atrributes.context.invalidDomains = dataset.invalidDomains;
    }

    logger?.api?.pushLog?.([ LogMessage.invalidDomainCount ], atrributes);
}

function displayWarning() {
    const { document, $, EV_LOGGER: logger } = window;
    const { documentElement } = document;
    const { dataset } = documentElement;
    const invalidDomains = Number(dataset.invalidDomains || 0);
    const alertElement = document.querySelector<HTMLElement>(Selector.alert);
    const domainValidationWarningTemplate = document.querySelector<HTMLTemplateElement>(Selector.warningTemplate);
    const domainValidationWarning = domainValidationWarningTemplate?.innerHTML;

    if (!expired && invalidDomains && alertElement && domainValidationWarning) {
        $?.(alertElement)?.trigger(AlertTrigger.error, { message: domainValidationWarning, timeout });

        logger?.api?.pushLog?.([LogMessage.displayInvalidDomainBanner], {
            level: LogLevel.INFO
        });

        requestIdleCallback(handleAlertDisplayed);
    }
}

function handleGetInvalidDomainsError(error: Error) {
    const { EV_LOGGER: logger } = window;
    const { message } = error;
    const errorMessage = `${ErrorMessage.errorGettingInvalidDomains}: ${message}`;


    logger?.api?.pushError?.(error);
    logger?.api?.pushError?.(new Error(errorMessage));
}

function handleAlertDisplayed() {
    const { body } = document;
    const closeButton = body?.querySelector<HTMLElement>(Selector.closeButton);
    const warningMessage = body?.querySelector<HTMLElement>(Selector.warningMessage);

    if (warningMessage) {
        warningMessage.style.boxShadow = 'none';
    }

    closeButton?.addEventListener?.('click', handleCloseButtonClick);
}

function handleCloseButtonClick() {
    const { EV_LOGGER: logger } = window;
    const { body } = document;
    const closeButton = body?.querySelector<HTMLElement>(Selector.closeButton);

    logger?.api?.pushEvent(EventMessage.invalidDomainBannerCloseClick);

    closeButton?.removeEventListener?.('click', handleCloseButtonClick);
}