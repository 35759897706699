const regex = {
    imageCompositionUrl: /\/compose\/\d+/
};

export function isImageCompositionUrl() {
    const { location } = window;
    const { pathname } = location;
    const result = Boolean(pathname.match(regex.imageCompositionUrl));

    return result;
}