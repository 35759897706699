import { IGetTemplate, IGetTemplateContent, IGetProcessedImage } from '../types';
import { processImageUrlsConfig as config  } from '../config';

export function processImageUrls({ html = '' }) {
    const content = getContent({ html });

    return content;
}

function getContent({ html = '' }) {
    const template = getTemplate({ html });
    const images = getImages({ template });
    const content = images.length ? getTemplateContent({ html, template, images }) : html;

    return content;
}

function getTemplate({ html = '' }) {
    const template = document.createElement('template');

    template.innerHTML = html;

    return template;
}

function getImages({ template }: IGetTemplate['params']) {
    const { selector } = config;
    const images = Array.from(template.content.querySelectorAll<HTMLImageElement>(selector.image));

    return images;
}

function getTemplateContent({ html, template, images }: IGetTemplateContent['params']) {
    const promcessedImages = images.filter(image => processImage({ image, html }));
    const templateContent = promcessedImages.length ? Array.from(template.content.children).map(({outerHTML}) => outerHTML).join('') : html;

    return templateContent;
}

function processImage({ image, html }: IGetProcessedImage['params']) {
    const { regex, replace } = config;
    const { src: url } = image;

    let processedImage = false;

    if (html.indexOf(url) === -1) {
        image.src = url.replace(regex.spaces, replace.htmlSpace);

        processedImage = true;
    }

    return processedImage;
}