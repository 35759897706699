export function getRegex({ patterns }: IGetRegex) {
    const regex: IRegex = {};

    for (const [key, pattern] of Object.entries(patterns)) {
        regex[key] = new RegExp(pattern);
    }

    return regex;
}

interface IGetRegex {
    patterns: Record<string, string>;
}

interface IRegex {
    [key: string]: RegExp;
}