const Selector = {
    sdk: '[src$="everlytic.lib.js"]'
};
const Regex = {
    sdkPath: new RegExp('/everlytic.lib.js$')
};

export function getSdkRootUrl() {
    const sdkScript = document.querySelector<HTMLScriptElement>(Selector.sdk) || { src: '' };
    const { src: sdkUrl = '' } = sdkScript;
    const sdkRootUrl = sdkUrl.replace(Regex.sdkPath, '');

    return sdkRootUrl;
}