import { camelCase, startCase } from 'lodash-es';
import { getLoggerConfig } from './get-logger-config.util';

const EventName = {
    unknown: 'UNKNOWN'
} as const;
const Suffix = {
    readableLabel: 'Label'
} as const;
const metaFields = [
    'i',
    'e',
    'c',
    'u'
] as const;
const { environment } = getLoggerConfig()

export function logEvent(params: ILogEvent) {
    const { EV_LOGGER: logger, AMPLITUDE } = window;
    const { eventName = EventName.unknown, readableLabels = [], amplitude = false, attributes: currentAttributes = {} } = params;
    const currentEventName = getEventName({ eventName });
    const attributes = getAttributes(currentAttributes );
    const updatedAttributes = getUpdatedAtributes({ attributes, readableLabels });

    logger?.api?.pushEvent?.(currentEventName, updatedAttributes);

    if (amplitude) {
        AMPLITUDE?.logEvent?.(currentEventName, updatedAttributes);
    }
}

function getAttributes(currentAttributes: Record<string, string>) {
    const attributes = {} as Record<string, string>;

    for (const [key, value] of Object.entries(currentAttributes)) {
        attributes[key] = value?.toString?.();
    }

    return attributes;
}

function getUpdatedAtributes({ attributes = {}, readableLabels = [] }: Pick<ILogEvent, 'attributes' | 'readableLabels'>) {
    const updatedAttributes = {
        ...attributes
    };

    let readableLabelKey: string;

    for (const readableLabel of readableLabels) {
        if (attributes[readableLabel]) {
            readableLabelKey = camelCase(`${readableLabel}${Suffix.readableLabel}`);

            updatedAttributes[readableLabel] = camelCase(attributes[readableLabel]);
            updatedAttributes[readableLabelKey] = startCase(attributes[readableLabel]);
        }
    }

    return updatedAttributes;
}

function getEventName({ eventName }: IGetEventName) {
    const { EV_CORE_META = {}, EV_CORE } = window;
    const { EventNameSuffix } = EV_CORE;

    let eventNameParts = new Set<string>();
    let metaFieldValue: number;

    eventNameParts.add(eventName);

    if (environment === 'Local') {
        eventNameParts.add(EventNameSuffix.LOCAL);
    } else if (environment === 'Dev QA') {
        eventNameParts.add(EventNameSuffix.DEV_QA);
    } else if (environment === 'Regression QA') {
        eventNameParts.add(EventNameSuffix.REGRESSION_QA);
    }

    for (const metaField of metaFields) {
        metaFieldValue = Number(EV_CORE_META?.[metaField]);

        if (isNaN(metaFieldValue) || metaFieldValue < 0) {
            if (metaField === 'i') {
                eventNameParts.add(EventNameSuffix.UNKNOWN_INSTALL_ID)
            } else if (metaField === 'e') {
                eventNameParts.add(EventNameSuffix.UNKNOWN_ENTERPRISE_ID)
            } else if (metaField === 'c') {
                eventNameParts.add(EventNameSuffix.UNKNOWN_CUSTOMER_ID)
            } else if (metaField === 'u') {
                eventNameParts.add(EventNameSuffix.UNKNOWN_USER_ID)
            }
        }
    }

    return Array.from(eventNameParts).join('_');
}

interface ILogEvent {
    eventName: string;
    attributes?: Record<string, string>,
    readableLabels?: string[];
    amplitude?: boolean;
}

interface IGetEventName {
    eventName: string;
}