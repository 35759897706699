export function getFormData({ selector = '', fields = {}, inputFields = {}, selectFieds = {}}: IGetFormData) {
    const { body } = document;
    const form = body.querySelector<HTMLFormElement>(selector);
    const formData = form && new FormData(form);
    const formFieldData = formData && getFormFieldData({ formData, fields }) || {};
    const inputFieldData = getInputFieldData({ inputFields });
    const selectFieldData = getSelectInputFieldData({ selectFieds });
    const data = {
        ...formFieldData,
        ...inputFieldData,
        ...selectFieldData
    };

    return data;
}

function getFormFieldData({ formData, fields}: IGetFormFieldData) {
    const formFieldData: Record<string, string> = {};

    let formField;

    for (const [key, field] of Object.entries(fields)) {
        formField = formData.get(field);

        if (formField) {
            formFieldData[key] = formField.toString();
        }
    }

    return formFieldData
}

function getInputFieldData({ inputFields = {} }: Pick<IGetFormData, 'inputFields'>) {
    const { body } = document;
    const inputFieldData: Record<string, string> = {};

    let input;

    for (const [key, selector] of Object.entries(inputFields)) {
        input = body.querySelector<HTMLInputElement>(selector);

        if (input?.value) {
            inputFieldData[key] = input.value
        }
    }

    return inputFieldData;
}

function getSelectInputFieldData({ selectFieds = {} }: Pick<IGetFormData, 'selectFieds'>) {
    const { body } = document;
    const selectFieldData: Record<string, string> = {};

    let selectInput;
    let option;

    for (const [key, selector] of Object.entries(selectFieds)) {
        selectInput = body.querySelector<HTMLSelectElement>(selector);
        option = selectInput?.options?.[selectInput.selectedIndex]?.innerHTML;

        if (option) {
            selectFieldData[key] = option;
        }
    }

    return selectFieldData;
}

interface IGetFormData {
    selector?: string;
    fields?: Record<string, string>;
    inputFields?: Record<string, string>;
    selectFieds: Record<string, string>;
}

interface IGetFormFieldData {
    formData: FormData;
    fields: Record<string, string>;
}