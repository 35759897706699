import { ObjectEntries, HostKey } from './index';
import { host as configHost, cdn as configCdn, ignoreErrors, loadScripts } from './config.json';
import { getSdkRootUrl } from './util/get-sdk-root-url';
import { getBrowserInfo } from './util/get-browser-info';
import { getLoggerConfig } from './util/get-logger-config.util';
import { getViewName } from './util/get-view-name.util';

const { location, EV_CORE_META = {} } = window;
const viewName = getViewName();
const { i: install } = EV_CORE_META;
const apiKey = import.meta.env.EVERLYTIC_FARO_API_KEY;
const name = import.meta.env.EVERLYTIC_FARO_APP_NAME;
const version = import.meta.env.EVERLYTIC_FARO_APP_VERSION;
const domainValidationWarningTimeout = import.meta.env.EVERLYTIC_DOMAIN_VALIDATION_WARNING_TIMOUT;
const domainValidationWarningExpiry = import.meta.env.EVERLYTIC_DOMAIN_VALIDATION_WARNING_EXPIRY;
const hostKey = getHostKey();
const cdn = configCdn[hostKey];
const sdkRootUrl = getSdkRootUrl();
const loadScriptUrls = (loadScripts || []).map(loadScript => `${sdkRootUrl}/${loadScript}`);
const { environment, userId } = getLoggerConfig();
const appNamespace = Number(install) ? `Live ${install}` : environment;
const browser = getBrowserInfo();

export const config = {
    a: apiKey,
    n: name,
    v: version,
    domainValidationWarningTimeout,
    domainValidationWarningExpiry,
    cdn,
    ignoreErrors,
    loadScripts: loadScriptUrls,
    environment,
    userId,
    appNamespace,
    browser,
    viewName
};

export const processImageUrlsConfig = {
    selector: {
        image: 'img'
    },
    regex: {
        spaces: /\s/g
    },
    replace: {
        htmlSpace: encodeURIComponent(' ')
    }
};


function getHostKey() {
    let hostKey: HostKey = 'prod';

    for (const [key, hosts] of ObjectEntries(configHost)) {
        if (hosts.includes(location.host)) {
            hostKey = key;

            break;
        }
    }

    return hostKey;
}