import { config } from '../config';

export function loadScripts({ scripts = [], timestamp = false }: ILoadScripts) {
    const loadedScripts = Promise
        .all(scripts.map(url => loadScript({ url, timestamp })))
        .catch(error => handleError({ error }));

    return loadedScripts;
}

export function loadScript({ url = '', timestamp = false }) {
    const { head } = document;
    const script = document.createElement('script');
    const currentTime = timestamp ? (new Date()).getTime() : '';
    const src = currentTime ? `${url}?d=${currentTime}` : url;

    script.src = src;

    return new Promise((resolve, reject) => {
        script.addEventListener('load', resolve);
        script.addEventListener('error', reject);

        head.appendChild(script);
    });
}

function handleError({ error }: { error: Error }) {
    console.error(error);
}

export async function importLoadScripts() {
    const { loadScripts: scripts = [] } = config;

    await loadScripts({ scripts });
}

interface ILoadScripts {
    scripts: string[];
    timestamp?: boolean;
}