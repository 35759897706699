import { initializeFaro, BrowserConfig, MetaApp, MetaUser, MetaView } from '@grafana/faro-web-sdk';
import { config  } from '../config';
import { host as hostConfig } from '../../lib/config/lib.config.json';
import { updateDependencyData } from '../util/dependency.util';
import { relayLogItem } from '../util/relay-log-item.util';

const { EV_CORE_META = {}, document, requestIdleCallback = setTimeout } = window;
const { a: apiKey, n: name, v: defaultVersion, ignoreErrors = [], userId, environment, appNamespace, browser, viewName } = config;
const { dev = [], staging = [] } = hostConfig;
const { origin, host } = location;
const {  documentElement } = document;
const everlyticRelayLogging = Number([...dev, ...staging].includes(host) || documentElement?.dataset?.everlyticRelayLogging || 0);
const {
    e = '',
    en = '-',
    c = '',
    cn = '-',
    u = '',
    i = origin,
    v = defaultVersion,
    cocv = '',
    cod = ''
} = EV_CORE_META;
const user: MetaUser = {
    id: userId,
    username: userId,
    attributes: {
        e,
        en,
        c,
        cn,
        u,
        i,
        v,
        cocv: Number(cocv).toString(),
        cod: Number(cod).toString(),
        ...browser
    }
};
const url = `${origin}/__/journal`;
const app: MetaApp = {
    name,
    namespace: appNamespace,
    version: v,
    release: environment,
    environment: appNamespace
};
const regex = {
    ignoreError: new RegExp(ignoreErrors.join('|'))
};
const beforeSend = everlyticRelayLogging ? beforeSendRelayHandler : beforeSendHandler;
const initializeConfig: BrowserConfig = { 
    url, 
    apiKey, 
    app, 
    user,
    beforeSend
};
const viewConfig: MetaView = {
    name: viewName
}

initialize();

function initialize() {
    const faro = initializeFaro(initializeConfig);

    faro.api.setView(viewConfig);
    
    window.EV_LOGGER = faro;

    requestIdleCallback(updateDependencyData);
}

function beforeSendHandler(itemParams: any) {
    const item: IItem = itemParams;

    let currentItem: any;
    let ignore = false;
    
    switch(item.type) {
        case 'exception':
            ignore = Boolean(item?.payload?.value?.match?.(regex.ignoreError));

            if (!ignore) {
                currentItem = itemParams;
            }

            break;
        default:
            currentItem = itemParams;
            
            break;
    }

    return currentItem;
}

function beforeSendRelayHandler(item: any) {
    relayLogItem(item);

    return undefined;
}

interface IItem {
    type: 'exception' | string;
    payload: {
        value?: string;
    };
}