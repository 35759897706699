import { getRegex } from './get-regex.util';
import { regex, selector as Selector, MessageType, ViewName, ViewNameSuffix, embedDepth, symbol } from '../config.json';

const PageUrl = getRegex({
    patterns: regex.pageUrl
});

export function getViewName() {
    const { 
        location, 
        EV_CORE_META = {},
        parent
    } = window;
    const { pathname, href, search } = location;
    const { u: userId } = EV_CORE_META;
    const viewUrls = [
        pathname,
        href
    ];
    const viewName = window === parent ? getViewNameFromUrl({ viewUrls, userId, search }) : getViewNameForIframe({ search });

    return viewName;
}

function getViewNameFromUrl(params: IGetViewNameFromUrl) {
    const {
        currentWindow = window,
        viewUrls = [],
        userId,
        search = ''
    } = params;
    const { message_type: messageTypeInput } = window;
    const messageType = messageTypeInput?.value;
    
    let { unknown: viewName } = ViewName;
    let viewNameSuffix;
    let fullViewName;

    matchViewUrlName: for (const [currentViewName, pageUrl] of Object.entries(PageUrl)) {
        for (const viewUrl of viewUrls) {
            if (viewUrl.match(pageUrl)) {
                viewName = currentViewName;

                break matchViewUrlName;
            }
        }
    }

    if (viewName !== ViewName.login && Number(userId) < 0) {
        viewName = `${viewName}: ${ViewNameSuffix['Logged Out']}`;
    } else if (viewName === ViewName.contactManager && !document.querySelector(Selector.contactManager)) {
        viewName = `${viewName}: ${ViewNameSuffix.Legacy}`;
    } else if (viewName === ViewName.bulkSmsComposition && messageType === MessageType.workflowSms) {
        viewName = ViewName.workflowSmsComposition;
    } else if (viewName === ViewName.bulkEmailCompositionTemplates && messageType === MessageType.autoResponder) {
        viewName = ViewName.autoResponderCompositionTemplates;
    } else if (viewName === ViewName.bulkEmailCompositionProperties && messageType === MessageType.autoResponder) {
        viewName = ViewName.autoResponderCompositionProperties;
    } else if (viewName === ViewName.bulkEmailComposition && messageType === MessageType.autoResponder) {
        viewName = ViewName.autoResponderComposition;
    } else if (viewName === ViewName.bulkEmailCompositionImportFromUrl && messageType === MessageType.autoResponder) {
        viewName = ViewName.autoResponderCompositionImportFromUrl;
    } else if (viewName === ViewName.bulkEmailCompositionUploadFromZip && messageType === MessageType.autoResponder) {
        viewName = ViewName.autoResponderCompositionUploadFromZip;
    } else if (viewName === ViewName.bulkEmailCompositionDeliveryOptions && messageType === MessageType.autoResponder) {
        viewName = ViewName.autoResponderCompositionDeliveryOptions;
    } else if (viewName === ViewName.bulkEmailCompositionConfirmation && messageType === MessageType.autoResponder) {
        viewName = ViewName.autoResponderCompositionConfirmation;
    } else if (viewName === ViewName.bulkEmailCompositionReport && messageType === MessageType.autoResponder) {
        viewName = ViewName.autoResponderCompositionReport;
    } else if (viewName === ViewName.bulkEmailCompositionHowToCompose && messageType === MessageType.campaign) {
        viewName = ViewName.bulkEmailCampaignCompositionHowToCompose;
    } else if (viewName === ViewName.bulkEmailCompositionTemplates && messageType === MessageType.campaign) {
        viewName = ViewName.bulkEmailCampaignCompositionTemplates;
    } else if (viewName === ViewName.bulkEmailComposition && messageType === MessageType.campaign) {
        viewName = ViewName.bulkEmailCampaignComposition;
    } else if (viewName === ViewName.bulkEmailCompositionImportFromUrl && messageType === MessageType.campaign) {
        viewName = ViewName.bulkEmailCampaignCompositionImportFromUrl;
    } else if (viewName === ViewName.bulkEmailCompositionUploadFromZip && messageType === MessageType.campaign) {
        viewName = ViewName.bulkEmailCampaignCompositionUploadFromZip;
    } else if (viewName === ViewName.bulkEmailCompositionPublished && document.querySelector(Selector.apiCommunicatorHistoryLink)) {
        viewName = ViewName.bulkApiCommunicatorHistory;
    } else if (viewName === ViewName.bulkApiCommunicatorReport && search) {
        viewName = ViewName.workflowApiCommunicatorReport;
    }

    viewNameSuffix = getViewNameSuffix({ currentWindow, viewName });
    fullViewName = viewNameSuffix ? `${viewName}: ${viewNameSuffix}` : viewName;

    return fullViewName;
}

function getViewNameSuffix(params: IGetViewNameSuffix) {
    const { currentWindow = window, viewName = ViewName.unknown } = params;
    let viewNameSuffix;

    if (ViewName.messageComposition.includes(viewName)) {
        viewNameSuffix = getViewNameSuffixForMessageComposition({ currentWindow });
    }

    return viewNameSuffix;
}

function getViewNameSuffixForMessageComposition(params: IGetViewNameSuffixForMessageComposition) {
    const { currentWindow = window } = params;
    const { document } = currentWindow;
    const builder = Boolean(document.querySelector(Selector.builder));
    const classic = Boolean(document.querySelector(Selector.classic));
    const coder = Boolean(document.querySelector(Selector.coder))
    
    let viewNameSuffix;

    if (builder) {
        viewNameSuffix = ViewNameSuffix.Builder;
    } else if (classic) {
        viewNameSuffix = ViewNameSuffix.Classic;
    } else if (coder) {
        viewNameSuffix = ViewNameSuffix.Coder;
    }

    return viewNameSuffix;
}

function getViewNameForIframe(params: IGetViewNameForIframe) {
    const { 
        currentWindow = window,
        viewNames = [],
        search = ''
    } = params;
    const { parent: currentParent } = currentWindow;
    const { location: currentLocation } = currentWindow;
    const { pathname, href } = currentLocation;
    const viewUrls = [
        pathname,
        href
    ];
    const viewName = getViewNameFromUrl({
        currentWindow,
        viewUrls,
        search
    });
    const viewNameSuffix = getViewNameSuffix({ viewName });
    const fullViewName = viewNameSuffix ? `${viewName}: ${viewNameSuffix}` : viewName;

    viewNames.unshift(fullViewName);

    if (viewNames.length <= embedDepth && currentWindow !== currentParent) {
        if (document.querySelector(Selector.subscriptionFormIframe)) {
            viewNames.push(ViewName.embeddedSubscriptionForm);
        }
        
        getViewNameForIframe({
            currentWindow: currentParent,
            viewNames,
            search
        });
    }

    if (viewNames.length > embedDepth) {
        viewNames.push(symbol.embedDepthExceeded)
    }

    return viewNames.join(symbol.nestedView);
}

interface IGetViewNameFromUrl {
    currentWindow?: Window;
    viewUrls?: string[];
    userId?: string;
    search: string;
}

interface IGetViewNameSuffix {
    currentWindow?: Window;
    viewName?: string;
}

interface IGetViewNameSuffixForMessageComposition extends Pick<IGetViewNameSuffix, 'currentWindow'> {}

interface IGetViewNameForIframe extends Pick<IGetViewNameFromUrl, 'currentWindow'>, Pick<IGetViewNameFromUrl, 'search'> {
    viewNames?: string[];
}