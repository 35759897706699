import { MetaView } from '@grafana/faro-web-sdk';
import { getViewName } from './get-view-name.util';

export function updateViewName() {
    const { EV_LOGGER: logger } = window;
    const viewName = getViewName();
    const viewConfig: MetaView | undefined = viewName ? {
        name: viewName
    }: undefined

    if (logger && viewConfig) {
        logger.api.setView(viewConfig);
    }
}