export enum HtmlSanitiser {
    processImageUrls = 'process-image-urls'
}

export interface IGetTemplateParams {
    template: HTMLTemplateElement;
}

export interface IGetTemplate {
    params: IGetTemplateParams;
}

export interface IGetTemplateContentParams extends IGetTemplateParams {
    html: string;
    images: HTMLImageElement[];
}

export interface IGetTemplateContent {
    params: IGetTemplateContentParams;
}

export interface IGetProcessedImageParams extends Pick<IGetTemplateContentParams, 'html'> {
    image: HTMLImageElement;
}

export interface IGetProcessedImage {
    params: IGetProcessedImageParams;
}

export interface IHtmlSanitiser extends Pick<IGetTemplateContentParams, 'html'> {
    pipeline: HtmlSanitiser[];
}