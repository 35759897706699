import { IHtmlSanitiser, HtmlSanitiser } from '../types';
import { processImageUrls } from './process-image-urls';

const sanitisers = {
    [HtmlSanitiser.processImageUrls]: processImageUrls
};

export function getSanisedHtml({ html = '', pipeline = [] }: IHtmlSanitiser) {
    let sanitisedHtml = html;

    for (const sanitiser of pipeline) {
        sanitisedHtml = sanitisers?.[sanitiser]?.({ html: sanitisedHtml }) || html;
    }

    return sanitisedHtml;
}