import { StatusIcon, TestSuite } from '../config.json';
import { getViewName } from './get-view-name.util';

const Regression = TestSuite.Regression as IRegression;

export function getRegressionTestSuite() {
    const viewName = getViewName();
    let regressionTestSuite = {
        name: '',
        status: '',
        label: ''
    };

    for (const [testSuiteName, testSuite] of Object.entries(Regression)) {
        if (testSuite.interface.includes(viewName)) {
            regressionTestSuite.name = testSuiteName;
            regressionTestSuite.status = StatusIcon[testSuite.status] || '';
            regressionTestSuite.label = [regressionTestSuite.status, regressionTestSuite.name].join(' ');
        }
    }

    return regressionTestSuite;
}

type Status = keyof typeof StatusIcon;

interface ITestSuite {
    interface: string[];
    status: Status;
}

interface IRegression {
    [K: string]: ITestSuite;
}