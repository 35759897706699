import { ExceptionEvent, LogEvent, LogLevel } from '@grafana/faro-web-sdk';

const { requestIdleCallback = setTimeout } = window;
const { head, documentElement } = document;
const RelayLogKey = {
    exception: 'RELAY_EXCEPTION',
    log: 'RELAY_LOG'
} as const;
const MimeType = {
    json: 'application/json'
} as const;
const Selector = {
    relayLogItem: 'script[data-relay-log-item]'
} as const;
const MessageType = {
    logEvent: 'EVERLYTIC_RELAY_LOG_EVENT'
} as const;

export function relayLogItem(item: RelayLogItem<RelayLogItemType>) {
    const { type } = item;

    if (type === 'exception') {
        relayExceptionItem(item);
    } else if (type === 'log') {
        relayLogEventItem(item);
    }
}

export function relayLogItemData() {
    const relayLogItems = getLogItems();
    
    relayLogItems.forEach(relayLogItem => relayLogItemEvent({ relayLogItem }));

    updateLogItemData();
}

function relayLogItemEvent({ relayLogItem }: IFelayLogItemEvents) {
    const { 'everlytic-origin': everlyticOrigin } = window;
    const { innerHTML: data } = relayLogItem;
    const message = {
        type: MessageType.logEvent,
        data
    };
    
    everlyticOrigin?.contentWindow?.postMessage?.(message);

    relayLogItem?.parentElement?.removeChild?.(relayLogItem);
}

function relayExceptionItem(item: IExceptionEventItem) {
    const { stacktrace = {}, context: itemContext = {}, timestamp, value } = item.payload;
    const context = {
        ...itemContext,
        stacktrace: JSON.stringify(stacktrace),
        value
    }
    const { ERROR: level } = LogLevel;
    
    logItemData({
        key: RelayLogKey.exception,
        context,
        timestamp,
        level
    });

    console.error(item);
}

function relayLogEventItem(item: ILogEventItem) {
    const { context: itemContext = {}, timestamp, message, level } = item.payload;
    const context = {
        ...itemContext,
        message
    };
    
    logItemData({
        key: RelayLogKey.log,
        context,
        timestamp,
        level
    });

    console.error(item);
}

function logItemData({ key, context, timestamp, level }: ExceptionEventLogItem | LogEventItem) {
    const { href: url } = location;
    const json = JSON.stringify({ 
        key,
        timestamp, 
        level,
        context: {
            ...context,
            url
        }
    });
    const script = document.createElement('script');

    script.type = MimeType.json;
    script.dataset.relayLogItem = '';
    script.innerHTML = json;

    head.appendChild(script);

    requestIdleCallback(updateLogItemData);
}

function updateLogItemData() {
    const { 'everlytic-origin': everlyticOrigin } = window;
    const relayLogItems = getLogItems();

    documentElement.dataset.relayLogItems = relayLogItems.length.toString();

    if (everlyticOrigin && relayLogItems.length) {
        requestIdleCallback(relayLogItemData);
    }
}

function getLogItems() {
    const relayLogItems = Array.from(document.querySelectorAll<HTMLScriptElement>(Selector.relayLogItem));

    return relayLogItems;
}

// TODO: Remove once devops sets up grafana faro for neutron and dev qa
handleMessage;

function handleMessage(event: MessageEvent) {
    const { EV_LOGGER: logger } = window;
    const { data: messageData = '{}'} = event;
    const logEventData = getLogEventData({ messageData });
    const validKey = logEventData?.key && Object.keys(RelayLogKey).includes(logEventData.key);

    if (validKey) {
        logger?.api?.pushLog?.(
            [logEventData.key], 
            {
                level: logEventData.level,
                context: logEventData.context
            }
        );
    }
}

function getLogEventData({ messageData }: IGetLogEventData) {
    let logEventData: ExceptionEventLogItem | LogEventItem | undefined;

    try {
        logEventData = typeof messageData === 'string' ? JSON.parse(messageData) : {};
    } catch(error) {
        logEventData = undefined;
    }

    return logEventData;
}

type RelayLogItemType = 'exception' | 'log';

type RelayLogItem<T extends RelayLogItemType> = T extends 'exception' ? IExceptionEventItem
    : T extends 'log' ? ILogEventItem
    : unknown;

type ExceptionEventLogItem = {
    key: typeof RelayLogKey.exception;
    context: ExceptionEvent['context'];
    level: LogLevel;
} & Pick<ExceptionEvent, 'timestamp'>

type LogEventItem = {
    key: typeof RelayLogKey.log;
    context: LogEvent['context'];
} & Pick<LogEvent, 'timestamp' | 'level'>

interface IExceptionEventItem {
    type: 'exception';
    payload: ExceptionEvent
}

interface ILogEventItem {
    type: 'log';
    payload: LogEvent
}

interface IFelayLogItemEvents {
    relayLogItem: HTMLScriptElement;
}

interface IGetLogEventData {
    messageData: string;
}