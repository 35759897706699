import { regex as Regex } from '../config.json';

const selector = {
    dynamicMinifiedScripts: `[src*="/min/?f="], script[src^="${origin}"]`
};
const regex = {
    cacheKey: /&h|\?d=.+$/,
    ignoreDynamicMinifiedScripts: new RegExp(Regex.ignoreDynamicMinifiedScripts)
};

export function getScriptData() {
    const dynamicMinifiedScripts = getDynamicMinifiedScripts();
    const scriptData = {
        dynamicMinifiedScripts: dynamicMinifiedScripts
    };

    return scriptData;
}

function getDynamicMinifiedScripts() {
    const { document, location } = window;
    const { origin } = location;
    const dynamicMinifiedScripts = Array
        .from(document.querySelectorAll<HTMLScriptElement>(selector.dynamicMinifiedScripts))
        .map(({src}) => src)
        .map(url => url.replace(origin, ''))
        .map(url => url.replace(regex.cacheKey, ''))
        .reduce(getdynamicMinifiedScriptsFromUrl, new Set<string>);

    return dynamicMinifiedScripts;
}

function getdynamicMinifiedScriptsFromUrl(dynamicMinifiedScripts = new Set<string>, dynamicMinifiedScriptsUrl = '') {
    const filePaths = dynamicMinifiedScriptsUrl.split(',');

    for (const filePath of filePaths) {
        if (!filePath.match(regex.ignoreDynamicMinifiedScripts)) {
            dynamicMinifiedScripts.add(filePath);
        }
    }

    return dynamicMinifiedScripts;
}