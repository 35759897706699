const EventName = {
    dependencyVersions: `DEPENDENCY_VERSIONS`
} as const;

export function getDependencyVersions() {
    const { jQuery, angular, ace } = window;
    const dependency = {
        jQuery: jQuery?.()?.jquery,
        jQueryUi: jQuery?.ui?.version,
        bootstrap: jQuery?.fn?.tooltip?.Constructor?.VERSION,
        angularJs: angular?.version?.full,
        ace: ace?.version,
    };
    const dependencyVersions = {} as IDependencyVersions;
    const jQueryCorrelationFields = [
        'jQuery',
        'jQueryUi',
        'bootstrap'
    ];
    const jQueryCorrelation = [];

    for (const [currentDependency, version] of Object.entries(dependency)) {
        if (version) {
            dependencyVersions[currentDependency] = version;
        }

        if (jQueryCorrelationFields.includes(currentDependency) && version) {
            jQueryCorrelation.push(`${currentDependency}: ${version}`);
        }
    }

    if (jQueryCorrelation.length > 1) {
        dependencyVersions.jQueryCorrelation = jQueryCorrelation.join(', ');
    }


    return dependencyVersions;
}

export function updateDependencyData() {
    setDependencyData();
    logDependencyData();
}

function setDependencyData() {
    const { document } = window;
    const { documentElement } = document;
    const { dataset } = documentElement;
    const dependency = getDependencyVersions();

    for (const [key, value] of Object.entries(dependency)) {
        dataset[key.toLowerCase()] = value;
    }
}

function logDependencyData() {
    const { EV_CORE } = window;
    const { logEvent } = EV_CORE;
    const dependencies = getDependencyVersions();
    
    logEvent({ eventName: EventName.dependencyVersions, attributes: dependencies });
}

export interface IDependencyVersions extends Record<string, string> {
    jQuery: string;
    jQueryUi: string;
    bootstrap: string;
    ace: string;
    angularJs: string;
    jQueryCorrelation: string;
}