import { getViewName } from './get-view-name.util';
import { getBrowserInfo } from './get-browser-info';
import { getLoggerConfig } from './get-logger-config.util';

const Selector = {
    logo: 'img[src*="users/enterprise_logos"]'
} as const;
const Environment = {
    local: 'Local',
    unknown: 'Unknown'
} as const;
const Enterprise = {
    unknown: 'Unknown Enterprise'
};
const Customer = {
    unknown: 'Unknown Customer'
};
const InstallId = {
    local: 999,
    qa: 0
} as const;

export function getInterfaceData() {
    const { 'stick-nav-username': usernameElement, EV_CORE_META, devicePixelRatio } = window;
    const { width: screenWidth, height: screenHeight, orientation } = screen;
    const { type: screenOrientation } = orientation;
    const { title: pageTitle } = document;
    const { hostname: host, pathname: path } = location;
    const { i = '-1', e = '-1', en = '-1', c = '-1', cn = '-1' } = EV_CORE_META || {};
    const installId = Number(i);
    const enterpriseId = Number(e);
    const customerId = Number(c);
    const enterpriseName = enterpriseId <= 0 ? Enterprise.unknown : en;
    const customerName = customerId <= 0 ? Customer.unknown : cn;
    const { environment } = getLoggerConfig();
    const environmentDescription = installId < 0 || isNaN(installId) ? Environment.unknown
        : installId === InstallId.local ? Environment.local
        : installId === InstallId.qa ? environment
        : `Live ${installId}`;
    const logoImage = document.querySelector<HTMLImageElement>(Selector.logo);
    const username = usernameElement?.innerHTML?.trim?.() || '';
    const enterpriseCustomer = enterpriseName === customerName ? enterpriseName : `${enterpriseName} - ${customerName}`;
    const enterpriseUser = username ? `${enterpriseCustomer}: ${username}` : enterpriseCustomer;
    const enterpriseLogo = logoImage?.src || '';
    const enterpriseLogoDescription = logoImage?.alt || '';
    const viewName = getViewName();
    const browser = getBrowserInfo();
    const interfaceData = {
        viewName,
        pageTitle,
        screenWidth: screenWidth?.toString?.() || '-1',
        screenHeight: screenHeight?.toString?.() || '-1',
        devicePixelRatio: devicePixelRatio?.toString?.() || '-1',
        screenOrientation,
        environmentDescription,
        host,
        path,
        username,
        ec: enterpriseCustomer,
        eu: enterpriseUser,
        enterpriseLogo, 
        enterpriseLogoDescription,
        ...browser
    };

    return interfaceData;
}