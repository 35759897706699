import { LogLevel } from '@grafana/faro-web-sdk';
import { IGetInvalidDomainsResponse } from '..';
import { fetchData } from '../util';

const LogMessage = {
    invalidDomain: 'INVALID_DOMAIN'
} as const;

export async function getInvalidDomains() {
    const invalidDomainsResponse = fetchData({ url: '/domains/invalid-domains' })
        .then(response => response && handleInvalidDomains(response));

    return invalidDomainsResponse;
}

function handleInvalidDomains({ invalidDomains = [] }: IGetInvalidDomainsResponse) {
    const { EV_LOGGER: logger } = window;

    for (const {domain_domain: domain, domain_id: domainId } of invalidDomains) {
        logger?.api?.pushLog(
            [LogMessage.invalidDomain], 
            { 
                level: LogLevel.INFO,
                context: { 
                    d: domain,
                    di: domainId,
                    depth: domain?.split('.')?.length?.toString?.() || '-1'
                }
            }
        );
    }

    return { invalidDomains };
}