import { loadScript } from './script.util';
import { isImageCompositionUrl } from './regex.util';
import { updateDependencyData } from './dependency.util';

const { requestIdleCallback = setTimeout } = window;
const config = {
    url: {
        ckfinder: '/vendors/ckfinder/ckfinder.js'
    },
    selector: {
        imageGalleryMenuItem: '#main-menu-image-gallery'
    },
    eventName: {
        click: 'click'
    },
    modal: {
        width: 800,
        height: 600,
        readOnly: false
    }
};

export function loadCkFinderPassive() {
    requestIdleCallback(loadCkFinder);
}

function loadCkFinder() {
    const { document, parent } = window;
    const { documentElement } = document;
    const { dataset } = documentElement;
    const loadCkFinder = window === parent && !isImageCompositionUrl();
    const ckFinderUrl = loadCkFinder && config.url.ckfinder;

    dataset.loadCkFinder = loadCkFinder.toString();

    if (ckFinderUrl) {
        loadScript({ url: ckFinderUrl })
            .then(() => requestIdleCallback(handleCkFinderLoaded))
            .catch(handleError);
    } else {
        requestIdleCallback(updateDependencyData);
    }
}

function handleCkFinderLoaded() {
    const { selector, eventName } = config;
    const imageGalleryMenuItem = document.querySelector(selector.imageGalleryMenuItem);

    imageGalleryMenuItem?.addEventListener?.(eventName.click, handleImageGalleryMenuItemClick);

    updateDependencyData();
}

function handleImageGalleryMenuItemClick() {
    const { CKFinder } = window;

    CKFinder.popup(config.modal);
}

function handleError({ error }: { error: Error }) {
    const { documentElement } = document;
    const { dataset } = documentElement;

    dataset.loadCkFinderError = true.toString();

    console.error(error);
}