import { logEvent } from './log-event.util';
import { getInterfaceData } from './get-interface-data.util';

const Eventname = {
    unmonitoredInterface: 'UNMONITORED_INTERFACE'
} as const;

export function handleUnmonitoredInterface() {
    const { unmonitoredInterface: eventName } = Eventname;
    const interfaceData = getInterfaceData();

    logEvent({ eventName, attributes: interfaceData });
}