export function setUrlData() {
    const {  location, EV_CORE_META = {} } = window;
    const { pathname, host } = location;
    const { documentElement } = document;
    const { dataset } = documentElement;

    dataset.host = host;
    dataset.urlPath = pathname;

    for (const [key, value] of Object.entries(EV_CORE_META)) {
        dataset[`evCore${key.toUpperCase()}`] = value;
    }
}