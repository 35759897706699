import * as Ace from 'ace-builds-1.15.2';
import { AceVersion } from '..';
import { config } from '../config';
import { lib as libConfig } from '../config.json';

const { ace } = window;
const { cdn } = config;
const version = ace?.version as AceVersion;
const options = libConfig?.ace?.[version] || {};
const basePath = cdn && options.basePath && [cdn, options.basePath].join('');

window.ace = ace || Ace;
window.define = ace.define;
window.require = ace.require;

basePath && window?.ace?.config?.set?.('basePath', basePath);

export {}