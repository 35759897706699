
import { FetchDataParams, FetchDataResponse } from '..';

async function handleResponse<P extends FetchDataParams>(response: Response)  {
    try {
        return await (response.json() as Promise<FetchDataResponse<P>>);
    } catch(error: any) {
        return handleResponseError({ error, response });
    }
}

async function handleResponseError({ error, response }: { error: Error, response: Response }) {
    const text = await response.text();
    const errorResponse = { error, text };

    Promise.reject(errorResponse);
}

export function fetchData<P extends FetchDataParams>(params: P) {
    const { HTML_API, URL: Url } = window;
    const { URL } = HTML_API;
    const { url = '' } = params;
    // TODO: The native Url constructor is overwritten in the product - breaks native fetch api
    // TODO: Don't override URL constructor in product - Correct this in the product;
    window.URL = URL;
    const response = fetch(url).then(handleResponse<P>);
    window.URL = Url;

    return response;
}